<template>
  <div>
    <h1 style="margin-bottom: 20px;" class="gansta-font lrg-font text-center"><span style="font-size: 1.5em">G</span>angsta <span style="font-size: 1.25em">S</span>copes</h1>
    <div v-if="!is16">
      <h2 class="text-center age-text">Is you 16 or Older ?</h2>
      <div class="text-center age-buttons">
        <button @click="is16Click()">Yes</button>
        <button @click="gotoPage(`https://dunks1980.com/`)">No</button>
      </div>
    </div>
    <div v-else>
        <div v-if="scopes && dayname && month && day">
          <p  style="margin: 0 auto 69px;" class="text-center text-block"><b>Daily Gangsta horoscope fo' {{day_dizzle(dayname)}} {{month}} {{day_of(day)}}<br> Here’s what tha fuck tha stars have up in store fo' you todizzle</b></p>
          <!-- <span>{{scopes}}</span> -->
          <div>
            <span class="text-center" v-for="scope in scopes" :key="scope.description">
              <h2 class="capitalize gansta-font mid-font text-center">{{sign_of(scope.sign)}} {{capital_of(scope.sign)}}</h2><br>
              <div class="flex-4">
                <span class="text-center">Yo' vibe: <b>{{scope.mood}}</b></span>
                <span class="text-center">dirty number: <b>{{scope.lucky_number}}</b></span>
                <span class="text-center">dirty color: <b>{{scope.color}}</b></span>
                <span class="text-center">dirty hour: <b>{{scope.lucky_time}}</b></span>
              </div>
              <div class="text-block">
                <span class="text-center">Whoz ass yo ass is compatible with: <b>{{scope.compatibility}}</b></span>
              </div>
              <div class="text-block">
                <p class="text-center text-para"><b>{{scope.description}}</b></p>
              </div>
              <div class="divider"></div>
            </span>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

const axios = require('axios');
//import json from './gangstascope.json'

export default {
  name: 'gangstascopes',
  props: {
    msg: String
  },
  data () {
    return {
      scopes: undefined,
      day: undefined,
      dayname: undefined,
      month: undefined,
      year: undefined,
      is16: false
    }
  },
  methods: {
    setCookie(name,value,days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    is16Click() {
      this.is16 = true
      this.setCookie("is16",true,30); //set "user_email" cookie, expires in 30 days
    },
    gotoPage(url) {
        window.location.href = url;
    },
    capital_of(i) {
      return i[0].toUpperCase() +  i.substring(1);
    },
    get_date(i){
      console.log("gangstascope date " + i);
      let dt = new Date(i);
      this.day = dt.getDate();
      this.dayname = dt.toLocaleDateString('default', { weekday: 'long' }); 
      this.month = dt.toLocaleString('default', { month: 'long' });
      this.year = dt.getFullYear();
      //console.log("gangstascope date full " + dt);
    },
    sign_of(i) {
      if (i == 'aries') {
          return '♈︎';
      }
      if (i == 'taurus') {
          return '♉︎';
      }
      if (i == 'gemini') {
          return '♊︎';
      }
      if (i == 'cancer') {
          return '♋︎';
      }
      if (i == 'leo') {
          return '♌︎';
      }
      if (i == 'virgo') {
          return '♍︎';
      }
      if (i == 'libra') {
          return '♎︎';
      }
      if (i == 'scorpio') {
          return '♏︎';
      }
      if (i == 'sagittarius') {
          return '♐︎';
      }
      if (i == 'capricorn') {
          return '♑︎';
      }
      if (i == 'aquarius') {
          return '♒︎';
      }
      if (i == 'pisces') {
          return '♓︎';
      }
    },
    day_of(i) {
      let j = i % 10,
      k = i % 100;
      if (j == 1 && k != 11) {
          return i + "st";
      }
      if (j == 2 && k != 12) {
          return i + "nd";
      }
      if (j == 3 && k != 13) {
          return i + "rd";
      }
      return i + "th";
    },
    day_dizzle(i) {
      if (i == 'Monday') {
          return 'Mondizzle';
      }
      if (i == 'Tuesday') {
          return 'Tuesdizzle';
      }
      if (i == 'Wednesday') {
          return 'Wensdizzle';
      }
      if (i == 'Thursday') {
          return 'Thursdizzle';
      }
      if (i == 'Friday') {
          return 'Fridizzle';
      }
      if (i == 'Saturday') {
          return 'Saturdizzle';
      }
      if (i == 'Sunday') {
          return 'Sundizzle ';
      }
    }
  },
  beforeMount() {
    let vm = this;
    let today = new Date();
    let getyesterday = new Date(today);
    let yesterday = new Date(getyesterday.setDate(getyesterday.getDate() - 1));
    let dd = today.getDate();
    let yd = yesterday.getDate();

    let mm = today.getMonth()+1; 
    let ym = yesterday.getMonth()+1; 

    const yyyy = today.getFullYear();
    const yyyy_yesterday = yesterday.getFullYear();
    if(dd<10) 
    {
        dd=`0${dd}`;
    } 
    if(yd<10) 
    {
        yd=`0${yd}`;
    } 
    if(mm<10) 
    {
        mm=`0${mm}`;
    } 
    if(ym<10) 
    {
        ym=`0${ym}`;
    }

    today = `${yyyy}-${mm}-${dd}`;
    yesterday = `${yyyy_yesterday}-${ym}-${yd}`;
    //today = `${yyyy}-${mm}-01`;
    console.log("browser date " + today);
    console.log("browser date yesterday " + yesterday);

    axios.get(`/gangstascopes_json/gangstascope_${today}.json`)
      .then((response) => {
        vm.scopes = response.data.data;
        vm.get_date(vm.scopes[0].current_date);
        console.log(`got gangstascope_${today}`);
        }).catch(error => {
          if (error) {
            console.log(error)
          }
          axios.get(`/gangstascopes_json/gangstascope_${yesterday}.json`) 
            .then((response) => {
              vm.scopes = response.data.data;
              vm.get_date(vm.scopes[0].current_date);
              console.log(`got gangstascope`);
            }).catch(error => {
              if (error) {
                console.log(error)
              }
              axios.get(`gangstascope.json`) .then((response) => {
                vm.scopes = response.data.data;
                vm.get_date(vm.scopes[0].current_date);
                console.log(`got gangstascope`);
                }
              )
            });
      });
    
  },
  mounted () {
    this.is16 = this.getCookie("is16");
    console.log("Gangstascopes");
  },
  computed: {
    // a computed getter
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

h3, h2 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.flex-4 {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 320px;
  flex-direction: column;
  span {
    padding: 0 10px;
  }
}
.text-block {
  padding: 0 10px;
  max-width: 600px;
  margin: 0 auto;
}

.age-text {
   margin: 40px auto 0;

}

$buttonBg: #1c2b48;

.age-buttons {
  margin: 0px auto 40px;
  button {
    margin: 10px;
    padding: 10px 25px;
    border: 0px solid #fff;
    border-radius: 5px;
    background: $buttonBg;
    color: #e7f0df;
    cursor: pointer;
    &:focus,
    &:hover {
      background: darken( $buttonBg , 4%);
    }
  }
}

.divider {
  min-height: 50px;
}

</style>
