<template>
  <div id="app">
    <gangsta />
    <footer>
      <div class="footer-inner">
        <h2 class="capitalize mid-font thinner-font text-center">more shiznit ta check out</h2>
        <div class="links">
          <a href="https://youtube.com/channel/UCw8w9J405UmddDbwY8FuyDA">subscribe to Freddy DoLess for gangsta music reviews</a>
          <a href="http://www.gizoogle.net/">gizoogle</a>
          <a href="https://dunks1980.com/">dunks1980</a>
          <a href="https://soundcloud.com/dunks1980">soundcloud</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import gangsta from "./components/gangsta.vue";

export default {
  name: "App",
  components: {
    gangsta
  }
};
</script>

<style lang="scss">
/* @import url("https://fonts.googleapis.com/css2?family=Pangolin&display=swap"); */

/* removes blue box on click */
input,
textarea,
button,
span,
div,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
button:focus {
  outline: 0;
}

$bg: #ebeadd;

body {
  margin: 0px;
  background: url('./assets/lowrider.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 0;
  color: #1c2b48;
}

* {
  font-family: Verdana;
  font-size: 15px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (min-width: 500px) {
      font-size: 16px;
      line-height: 1.5;
  }
}

@font-face { 
  font-family: "gangsta";
  src: url("./fonts/pure-evil-2.regular.ttf"); 
  font-display: swap;
}
.gansta-font, .gansta-font * {
  font-family: "gangsta", Verdana;
  line-height: 1;
}

#app {
  background:$bg;
  background: linear-gradient(90deg, rgba($bg,0.8) 0%, rgba($bg,1) 30%, rgba($bg,1) 70%, rgba($bg,0.8) 100%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 101vh;
}

footer {
  min-width: 100%;
  background: #1a202c;
  color: #e7f0df;
  .footer-inner {
    padding: 40px;
    margin: 0 auto 20px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      a[href] {
        color:#7dbe44;
        cursor: pointer;
        + a {
          margin-top: 20px;
        }
      }
    }
  }
}

.lrg-font {
  font-size: 100px;
  font-weight: lighter;
}
.mid-font {
  font-size: 69px;
  font-weight: lighter;
}
.thinner-font {
  font-size: 24px;
  font-weight: lighter;
}
.text-para, .text-para * {
  font-size: 15px;
  @media (min-width: 500px) {
   font-size: 16px;
  }
}

.capitalize:first-letter{
  text-transform: capitalize;
}
.text-center {
  text-align: center;
  display: block;
}

</style>
